import React, { useMemo, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../../firebase/config';
import {
    Link as MuiLink, Container, Box, TextField, Typography, CircularProgress, Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MotionBox from '../../components/motion/motionBox';
import LayoutType from '../../constants/layout-type';
import Colors from '../../constants/colors';

const RequestResetPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        setSuccessMessage(null);

        try {
            await sendPasswordResetEmail(auth, email);
            setSuccessMessage("Check your email for a link to reset your password.");
            // Optionally, navigate the user to the login page or show a message
            // navigate('/auth/login');
        } catch (error: any) {
            console.error("Error sending password reset email", error);
            setError("Failed to send password reset email. Please make sure the email is correct.");
        } finally {
            setLoading(false);
        }
    };

    const firstBoxAnimationProps = useMemo(() => ({
        initial: { opacity: 0, y: -50 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -50 },
        transition: { duration: 1 },
        style: { visibility: 'visible', background: Colors.BACKGROUND_DARK }
    }), []);

    return (<>
        <MotionBox
            {...firstBoxAnimationProps}
        >
            <Container maxWidth="md">
                <Box textAlign="center" pt={13} pb={2} mb={0} flexDirection="column" flex="1" alignItems="center" alignContent="center">
                    <Typography component="h1" variant="h1" sx={{ mt: 4, mb: 1, maxWidth: "100%" }}>
                        Reset Password
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                        Enter your email address below, and we'll send you a link to reset your password.
                    </Typography>
                </Box>
            </Container>
        </MotionBox>
        <Container component="main" maxWidth="sm">
            <Box sx={{ pb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 4, width: '100%', textAlign: 'center' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {loading ? (
                        <CircularProgress sx={{ mt: 2 }} />
                    ) : (
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            loading={loading}
                            sx={{ mt: 3, mb: 2, maxWidth: '75%' }}
                        >
                            Send Reset Link
                        </LoadingButton>
                    )}
                    {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
                    {successMessage && <Alert severity="success" sx={{ mt: 2 }}>{successMessage}</Alert>}
                </Box>
                <MuiLink to="/auth/login" color="inherit" component={Link} sx={{ pt: 2 }}>
                    <Typography variant="body2">
                        Remembered your password? Sign in
                    </Typography>
                </MuiLink>
            </Box>
        </Container>
    </>);
};

RequestResetPage.layoutType = LayoutType.DefaultFullWidth;

export default RequestResetPage;

export const Head: React.FC = () => <title>ScreenKeep - Request Password Reset</title>;